@import '../../../styles/colors';

.organism {
  display: grid;
  grid-template-columns: 20px 150px 260px 240px;
  grid-template-rows: 30px;
  grid-gap: 10px;
  padding: 5px;
  align-items: center;
  &_priority {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &_title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &_school {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &_student {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.organismitem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // grid-template-columns: 1fr 20px;
  // grid-gap: 5px;
  background-color: white;
  border: 1px solid $color-cancel;
  border-radius: 10px;
  padding: 2px 5px;
  height: 30px;
  font-size: 14px;
  cursor: pointer;
  .valued {
    background-color: $color-complementary-2;
  }
}

.organismitem:hover {
  .organismicon {
    display: block !important;
  }
}

.organismicon {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
  &.check {
    color: $color-check;
  }
  &.uncheck {
    color: $color-cancel;
  }
  &.school {
    color: $color-complementary-3;
  }
  &.student {
    color: $color-check;
  }
  &.remove {
    display: none !important;
    color: $color-delete;
  }
  &.delete {
    color: $color-delete;
  }
}
