@import '../../styles/colors';

.changepasswordreq {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 400px;
    border-radius: 10px !important;

    &_title {
      font-size: 18px;
      margin-bottom: 40px;
    }

    &_form {
      width: 300px;
      margin-bottom: 20px;
    }
    &_button {
      width: 200px;
    }

    &_error {
      &_text {
        text-align: center;
        padding: 0 20px;
        color: $color-delete;
      }
    }

    &_success {
      display: flex;
      flex-direction: row;
      &_icon {
        margin-right: 10px;
        height: 40px !important;
        width: 40px !important;
        color: $color-complementary;
      }
      &_text {
        &_message {
          font-size: 12px;
          color: $color-normal;
        }
      }

      &_link {
        margin-top: 20px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: row;

        a {
          font-size: 14px;
          text-decoration: none;
          color: $color-complementary;
          display: flex;
          flex-direction: row;
        }
      }
    }
    &_logos {
      width: 250px;
    }
  }
}
