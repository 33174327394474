@import '../../styles/colors';

.dashboard {
  &_header {
    justify-content: flex-end;
  }
  &_amount {
    width: 100%;
    text-align: right;
    padding-right: 10px;
  }
  &_paymentimage {
    color: $color-complementary;
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;
  }
}
