@import 'colors';

// Estilos Materials

// .MuiPaper-root {
//   background-color: white;
// }

// Estilos personalizados globales, usar prefijo g-

.g-page-header {
  padding: 10px;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.g-button {
  padding: 0;
  &.delete {
    background: $color-delete;
  }

  &.cancel {
    background: $color-medium;
    &:hover {
      background-color: $color-medium;
    }
  }

  &.disabled {
    background: $color-disabled;
    &:hover {
      background-color: $color-disabled;
    }
  }

  &.complementary {
    background: $color-complementary;
    &:hover {
      background-color: $color-complementary;
    }
  }

  &.check {
    background: $color-check;
    &:hover {
      background-color: $color-check;
    }
  }
}

.g-row-action-button {
  background: $color-primary;
  color: white;
  width: 20px !important;
  height: 20px !important;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  margin: 5px;

  &.delete {
    background: $color-delete;
  }

  &.disabled {
    background: $color-disabled;
    cursor: auto;
  }

  &.complementary {
    background: $color-complementary;
  }
}

.g-toast {
  border-radius: 10px !important;
  padding: 15px !important;
  border: 2px solid white;
}

// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
