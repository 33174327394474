@import '../../styles/colors';

.events {
  &_header {
    justify-content: flex-end;
  }
  &_alerttext {
    font-size: 12px;
    color: $color-delete;
  }
}
