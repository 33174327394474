@import '../../styles/colors';

$config-width: 740px;
$summary-width: 400px;

.eventconfiguration {
  &_pagetitle {
    width: $config-width;
  }
  &_content {
    display: flex;
    &_config {
      width: $config-width;
      min-width: $config-width;
      margin-right: 20px;
      &_controls {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 10px;
        &_tabs {
          color: $color-normal;
        }
      }
    }
  }

  &_summary {
    width: $summary-width;
    min-width: $summary-width;
    margin-top: 18px;
    &_title {
      display: flex;
      justify-content: space-between;
      &_download {
        color: white;
        background-color: $color-complementary-3;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        margin: 0 5px;
      }
    }

    &_card {
      padding: 10px;
      margin-bottom: 10px;
      &_title {
        border-bottom: 2px solid $color-light;
      }
    }
  }
}
