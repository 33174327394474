@import '../../styles/colors';

.assign {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  &_download {
    color: white;
    background-color: $color-complementary-3;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  &_confirm {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 200px;
  }
}
