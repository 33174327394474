@import '../../../styles/colors';

.committee {
  padding: 10px 20px;
  margin-bottom: 10px;
  &_header {
    padding-bottom: 5px;
    border-bottom: 2px solid $color-light;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &_buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      &_download {
        color: white;
        background-color: $color-complementary-3;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        margin: 0 5px;
      }
      &_delete {
        width: 25px !important;
        height: 25px !important;
        padding: 5px;
        color: $color-delete;
        cursor: pointer;
      }
      &_edit {
        background-color: $color_primary;
        color: white;
        width: 25px;
        height: 25px;
        border-radius: 4px;
        padding: 5px;
        cursor: pointer;
        margin: 5px;
      }
    }
  }
  &_content {
    // margin-bottom: 20px;
  }
  &_footer {
    border-top: 2px solid $color-light;
    margin-top: 10px;
    padding-top: 10px;
  }
}
