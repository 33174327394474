@import '../../../styles/colors';

.school-dist {
  font-size: 12px;
  padding-top: 10px;

  &_item {
    display: grid;
    grid-template-columns: 1fr 40px 40px 40px;
    grid-template-rows: 30px;
    grid-gap: 5px;
    align-items: center;
    &.header {
      font-weight: bold;
    }
    &_title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &_assign {
      width: 25px !important;
      height: 25px !important;
      color: $color-normal;

      &.check-btn {
        cursor: pointer;
      }
      &.checked {
        color: $color-check;
      }
    }
  }
}
