@import "../../../styles/colors";

.organismModal {
  display: grid;
  grid-template-columns: 20px 300px;
  grid-gap: 20px;
  // justify-content: flex-end;
  align-items: center;
  &_priority {
    width: 25px !important;
    height: 25px !important;
    color: $color-normal;
    cursor: pointer;
    &.checked {
      color: $color-check;
    }
  }
}
