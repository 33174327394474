@import '../../styles/colors';

.login {
  display: flex;
  flex-direction: row;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: $color-light !important;

  &_container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 85% 15%;
    align-items: center;
    justify-content: center;
    background-color: $color-white;
    border-radius: 10px !important;
    width: 1000px;
    min-width: 600px;
    max-width: 1000px;
    height: 400px;
    overflow: hidden;

    &_form {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 92px);

      width: 75%;
      align-self: center;
      justify-self: center;
      margin-top: 15%;

      &-button {
        text-align: right;
        width: 100%;
        margin-top: 1rem;
      }
    }

    &_recovery {
      grid-column: 1 / 3;
      grid-row: 2 / 3;

      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: 1fr;

      &-left {
        grid-column: 1 / 2;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 60% auto 39%;
        grid-column-gap: 20px;

        align-self: center;
        justify-self: center;
        width: 75%;

        align-items: center;

        &-divider {
          border-right: 3px solid $color-disabled;
          height: 60%;
        }

        p {
          justify-self: right;
        }

        a {
          justify-self: left;
          //text-decoration: none;
          color: $color-dark;
          cursor: pointer;

          &:hover {
            color: $color-medium;
            cursor: pointer;
            transition-delay: 60ms;
          }
        }
      }

      &-right {
        justify-self: end;
        align-self: center;
        padding-right: 40px;

        a {
          text-decoration: none;
          color: $color-dark;
          cursor: pointer;
          font-weight: lighter;
          //font-style: italic;
          font-size: 10pt;

          &:hover {
            color: $color-medium;
            cursor: pointer;
            transition-delay: 60ms;
          }
        }
      }
    }

    &_logos {
      padding-right: 40px;
    }
  }
}
