@import '../../styles/colors';

.schoolregistry {
  width: 700px;
  padding: 40px 20px 20px 20px;

  &_form {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
  }
  &_buttons {
    padding-top: 20px;
    display: flex;
  }
}
