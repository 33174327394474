$color-primary: #19325c;
$color-secondary: #f37722;
$color-complementary: #00c0ea;
$color-complementary-2: #e2eaf4;
$color-complementary-3: #807fff;
$color-complementary-4: #f9ec31;
$color-light: #f0f0f0;
$color-white: #ffffff;
$color-dark: #28292b;
$color-medium: #adadad;
$color-normal: #707070;
$color-disabled: #e4e4e4;
$color-cancel: #adadad;
$color-delete: #fa6347;
$color-check: #3bbb3e;
