@import '../../styles/colors';

.payment {
  width: 600px;
  padding: 10px;
  font-size: 14px;
  &_confirm {
    display: flex;
    align-items: center;
  }
  &-space {
    padding-top: 10px;
  }
  &_detail {
    padding: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    justify-items: end;
    &-header {
      font-family: 'OpenSans-Bold';
    }
    &-title {
      place-self: start;
    }
  }
  &_buttons {
    padding-top: 20px;
    display: flex;
    align-items: center;
    &_link {
      text-decoration: none;
    }
    Button:nth-child(1) {
      margin-right: 10px;
    }
    Button:nth-child(2) {
      margin-right: 10px;
    }
  }
}
