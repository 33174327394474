@import '../../../styles/colors';

.event-info {
    font-size: 12px;
    &_date{
        padding-top: 10px !important;
    }
    &_container{
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        margin-top: 1em;
        margin-bottom: 1em;
    }
}
