@import '../../../styles/colors';

.organization-dist {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  font-size: 12px;
  padding: 10px;

  &_organization {
    font-size: 10px;
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;
    background-color: white;
    padding: 5px;

    &_count {
      font-size: 12px;
      text-align: right;
    }
  }
}

.organization-dist_organization:nth-child(odd) {
  background-color: $color-light;
}
