@import './../../styles/colors';

$transition-speed: 0.2s;

.maincontainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  &_toolbar {
    background-color: $color-primary;
    border: none;
  }
  &_content {
    padding: 10px 30px;
  }
}
