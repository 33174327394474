@import './../../styles/colors';

.logos {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &_union {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-right: 2%;
    &_img {
      width: 100%;
      height: 100%;
    }
  }

  &_jfk {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 2%;
    height: 100%;
    width: 100%;
    &_img {
      width: 100%;
      height: 80%;
    }
  }
}
