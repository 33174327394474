@import '../../styles/colors';

.changepassword {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $color-white;
    width: 400px;
    padding: 20px;
    border-radius: 10px !important;

    &_title {
      font-size: 18px;
      margin-bottom: 40px;
    }

    &_form {
      width: 300px;
      margin-bottom: 20px;
    }

    &_button {
      width: 200px;
    }

    &_link {
      margin-top: 20px;
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;

      a {
        font-size: 14px;
        text-decoration: none;
        color: $color-complementary;
        display: flex;
        flex-direction: row;
      }
    }

    &_logos {
      width: 250px;
    }
  }
}
